import React from "react"

/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/outline"

const features = [
  {
    name: "Common Cities",
    description:
      "Bloomfield CT, Cromwell CT, East Hartford CT, East Windsor CT, Farmington CT, Glastonbury CT, Granby CT, Hartford CT, Manchester CT, Middletown CT, Newington CT, Portland CT, Rocky Hill CT, Simsbury CT, South Windsor CT, Vernon CT, West Hartford CT, Wethersfield CT, and Windsor CT.",
  },
  {
    name: "Airport Transfers",
    description:
      "John F. Kennedy Airport (JFK), LaGuardia Airport (LGA), Bradley International Airport (BDL), Tweed New Haven Airport (HVN), Newark Liberty Airport (EWR), and Logan International Airport (BOS)",
  },
  {
    name: "Popular Colleges & Universities",
    description:
      "University of Hartford, Trinity College, Wesleyan University, Yale University, Saint Joseph College, University of Connecticut (UConn), Goodwin College, Charter Oak State, and Middlesex Community College, ",
  },
  {
    name: "Common Corporate Offices",
    description:
      "Aetna, The Hartford, First Health Medical Group, Connecticut Transit, Accenture, Deloitte, Cigna, Prudential, and many more!",
  },
  {
    name: "Popular Destinations",
    description:
      "New York City, Boston, Six Flags, Lake Compounce Family Theme Park, Foxwoods Casino, Mohegan Sun Casino, The Mark Twain House Museum, Connecticut Science Center, Wadsworth Atheneum, State Capitol, Elizabeth Park Rose Garden, Bushnell Park, Harriet Beecher Stowe Center, Connecticut Historical Society Museum, Old State House ",
  },
  {
    name: "Wheelchair Accessible",
    description:
      "With Hartford Taxi Service, you'll never have to worry about getting around town! We provide a durable and comfortable ride in our wheelchair vans that will make your outing as easy-going as possible. The back gate folds down when we arrive at the scene so there's no need for an inaccessible ramp--you can get on board right away with just one tap of the button from where ever its safe or near enough without stepping onto busy streets first.",
  },
  {
    name: "On Demand or Easily Scheduled",
    description:
      "Our taxi cab service is available for on-demand needs, or you are able to plan in advance and schedule your cab for a specific time and our drivers will be on time, every time! Airports can cause all kinds of anxiety, don't feel the pressure and let Hartford Taxi Service handle your transportation needs.",
  },
  {
    name: "Sedans, Vans, and More",
    description:
      "Do you have a large party? Do you need a larger vehicle? Do you need multiple taxis? Call us and let us know what your needs are and we will work with you and your group to make sure everyone gets to the same location at the same time!",
  },
]

export default function Example() {
  return (
    <div className="py-16 px-4 sm:px-6 lg:py-24 lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div>
        <h2 className="text-base font-semibold text-yellow-400 uppercase tracking-wide">
          Anywhere you want to go
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900">
          Hartford Taxi Service Areas
        </p>
        <p className="mt-4 text-lg text-gray-500">
          Whether you're looking for an airport transfer, a ride to school, or a
          night out on the town, we have cab service for you. The distance
          doesn't matter!
        </p>
      </div>
      <div className="mt-12 lg:mt-0 lg:col-span-2">
        <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
          {features.map(feature => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon
                  className="absolute h-6 w-6 text-yellow-400"
                  aria-hidden="true"
                />
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
